import React from 'react';
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      'מעל 2 מיליארד אנשים ברחבי העולם משתמשים בטלפונים חכמים (סמארטפונים) ורוב האנשים עושים בו שימוש תכוף, בממוצע של עשרות פעמים ביום. הפופולריות של הטלפונים הניידים החכמים גורמת לחברות רבות לפתח אפליקציות WEB איכותיות ועדכניות, ויש מגמות חדשות ומובילות בתחום של פיתוח אפליקציות WEB שכדאי לכם להכיר.',
      'אנחנו בחברות אפספורס בוחרים את טכנולוגיית הפיתוח לאחר אפיון הצרכים והדרישות שלכם מהאפליקציה שעלינו לפתח. בשנים האחרונות צצו טכנולוגיות חדשניות המאפשרות לנו לפתח אפליקציות ווב מתקדמות המבוססות על בינה מלאכותית, מציאות רבודה, למידת מכונה, האינטרנט של הדברים ועוד.',
    ],
  },
  section2: {
    h: 'מה זה אפליקציות WEB?',
    p: [
      'אפליקציית WEB מתקדמת (PWA – Progressive Web Apps) היא מעין יצור כלאיים של אתר או דף אינטרנט רגיל ואפליקציית Native. למעשה, מדובר באתר אינטרנט חדשני המתנהג ופועל כמו אפליקציה, והמשתמשים ביישום יכולים לגשת למידע ולבצע פעולות שונות, ללא צורך בהורדת האפליקציה למכשיר הנייד. אפליקציות ווב מציעות יכולות מתקדמות שעד לאחרונה היו שמורות רק ליישומי ה-WEB המורכבים, לדוגמה איתור מקום, והמשתמשים זוכים לחוויית משתמש ייחודית וישירות בדפדפנים שלהם.',
      'דוגמה לאפליקציית WEB מנצחת ומתקדמת טכנולוגית היא האפליקציה של אלי אקספרס – אתר הסחר האלקטרוני הנחשב לאחד מהאתרים הפופולריים ביותר. כדאי לדעת כי מקבלי ההחלטות בחברה בחרו לפתח אפליקציית WEB חדשנית לאחר שהאתר שהותאם לנייד סיפק עבורם תוצאות מאכזבות. לאחר פיתוח אפליקציה ייעודית למובייל החברה שיפרה באופן משמעותי את חוויית המשתמש והביצועים הכוללים של אתר החברה, ותוך זמן קצר ממועד השקת האפליקציה החברה זכתה לעלייה מעל 100% בלידים, לעלייה של עשרות אחוזים בזמן השהייה במערכת והמשתמשים צפו בפי שניים עמודים בכל ביקור.',
    ],
  },
  section3: {
    h: 'מגמות מובילות בפיתוח אפליקציות WEB',
    ol: [
      {
        h3: '1. בינה מלאכותית – AI',
        p: [
          'אחד הטרנדים הבולטים והמובילים בפיתוח אפליקציות ל-WEB הוא השימוש ההולך וגובר בטכנולוגיית הבינה המלאכותית (AI – Artificial Intelligence). בינה מלאכותית מוגדרת על ידי המומחים כמיכון, מערכת, מחשב או טכנולוגיה אשר מסוגלים לבצע חישובים מורכבים במהירות ולדמות חשיבה אינטליגנטית כמו של בני אדם. לכן, לאפליקציות ווב יש יכולות למידה עצמית, בשילוב יכולות לאיסוף מידע וניתוח הנתונים, וכך האפליקציה מסוגלת לבצע משימות שונות שבעבר הלא רחוק רק בני אדם היו מסוגלים לבצע.',
          "ראוי לציין כי כבר היום יש מגוון רחב של פיצ'רים המבוססים על בינה מלאכותית ומיועדים לפיתוח אפליקציות ווב, למשל פיצ'ר מענה אוטומטי למסרים, פיצ'רים המאפשרים לחזות את כוונת המשתמשים, זיהוי קולי ועוד.",
        ],
      },
      {
        h3: '2. טכנולוגיית ענן – Cloud',
        p: [
          'אחד הטרנדים המובילים בתחום של פיתוח אפליקציות מגיע אלינו מפיתוח תוכנה ומערכות מתקדמות המבוססות על טכנולוגיית הענן. בתקופה האחרונה חלה עלייה משמעותית בכמות אפליקציות ה-WEB המשתמשות גם הן בטכנולוגיית הענן. הטרנד המוביל בתחום הפיתוח של אפליקציות ווב חוסך מקום בזיכרון של הסמארטפון ומשפר את חוויית המשתמש.',
          'המומחים של אפספורס מציינים כי ניתן להבחין בטרנד מוביל של פיתוח אפליקציות SaaS המבוססות בין היתר על הענן, כך המשתמשים לא צריכים להוריד את האפליקציה למכשיר שלהם כדי להשתמש בה, וכל הפעולות והשירותים נעשים באמצעות הדפדפן. יש שפע דוגמאות לאפליקציות SaaS המכונות בעברית תוכנות כשירות, ללא צורך בהורדה או התקנה, למשל שירות חשבונית ירוקה, בניית חנות אינטרנטית בפלטפורמות להקמת אתרי מכירות ועוד.',
        ],
      },
      {
        h3: '3. האינטרנט של הדברים – IOT',
        p: [
          'טרנד האינטרנט של הדברים מהווה את הגל הנוכחי של המהפכה הדיגיטלית בחיינו, הטכנולוגיה מאפשרת לחפצים ולמכשירים שונים לתקשר איתנו וזה עם זה באמצעות האינטרנט. הטכנולוגיה המובילה מאפשרת לנו לשלוט על המכשירים החכמים גם מרחוק, בצורה אוטומטית, נוחה ויעילה.',
          'למעשה, האינטרנט של הדברים יוצר חיבור ישיר בין העולם הפיסי והמוצרים השונים שיש לנו לבין רשת האינטרנט. המידע זורם במהירות בין המכשירים השונים המחוברים לאינטרנט ללא צורך באינטראקציה אנושית. פיתוח אפליקציות ווב התומכות בטכנולוגיית האינטרנט של הדברים הפך לדבר החם בתעשיית ההייטק, ותפקידה של האפליקציה הוא לתווך ולקשר בין הרכיבים והמכשירים השונים, כאשר בדרך כלל האפליקציה מאפשרת למשתמשים לשלוט מרחוק ובקלות באותם המכשירים, לצבע פעולות שונות, לנטר מידע באופן אוטומטי ולהביא לשיפור באיכות החיים של המשתמשים.',
        ],
      },
      {
        h3: '4. מציאות רבודה – AR',
        p: [
          'טרנד מוביל נוסף בפיתוח אפליקציות ווב הוא השילוב בין מציאות מדומה למציאות האמיתית, מה שהוביל לפיתוח המציאות הרבודה והאפליקציות האינטראקטיביות. AR היא טכנולוגיה חדשה יחסית שכבר עתה שינתה את עולמות המשחקים והאפליקציות. חברות רבות החלו לפתח אפליקציות ווב חדשניות המבוססות בין השאר על מציאות רבודה למגוון תחומים ותעשיות, לרבות תעשיית הגיימינג הפורחת מאוד בשנים האחרונות, תחום הרפואה והטיפול מרחוק וגם לתחום החינוך, הנדל"ן והמסחר האלקטרוני.',
        ],
      },
      {
        h3: '5. חוויית משתמש ייחודית',
        p: [
          <>
            {
              'חברות וארגונים הבינו כי עליהם להשקיע בשיפור חוויית המשתמש בכל המערכות, התוכנות והאפליקציות שלהם, ובפרט באפליקציות WEB. בפיתוח האפליקציות החדשות ניתן דגש רב על נושא '
            }
            <Link to={URL.UI_UX_DESIGN_SERVICE} active>
              אפיון ועיצוב UI/UX
            </Link>
            {' על מנת לספק למשתמשים חוויה ייחודית ומלהיבה.'}
          </>,
          'הטרנד המוביל בנושא פיתוח ממשקים לאפליקציות WEB הוא ממשק נוח, פשוט להבנה וקל לשימוש על ידי כל המשתמשים, גם אם האפליקציה משרתת משתמשים מארצות שונות ובשפות מגוונות.',
        ],
      },
      {
        h3: '6. זיהוי קולי',
        p: [
          "בתקופה האחרונה ניתן להבחין בטרנד מוביל בתחום הפיתוח של אפליקציות לווב להטמעת פתרונות זיהוי קולי באפליקציות המתקדמות. כאשר נכנסים לחנות של גוגל או של אפל ניתן להבחין כי ברשימת האפליקציות המובילות יש נוכחות מרשימה של אפליקציות העשות שימוש בממשק זיהוי קולי. בשנים האחרונות התרגלנו להפעלת האפליקציות המתקדמות באמצעות הקלדת פקודות ולאחר מכן עברנו להפעלה באמצעות מסכי הטאץ', אך כיום עוברים לממשקי הזיהוי הקולי המשפרים את חוויית המשתמש ומבטיחים תפעול נוח, מהיר ויעיל יותר של היישומים השונים.",
          <>
            {'מחפשים '}
            <Link to={URL.HOME} active>
            בית תוכנה מוביל 
            </Link>
            {' המתמחה בפיתוח אפליקציות WEB '}
            <Link to={URL.SAAS_CONSULTING_SERVICE} active>
            ואפליקציות SaaS 
            </Link>
            {' עם הטכנולוגיות המתקדמות והחדשניות ביותר? '}
            <Link to={URL.CONTACT} active>
            צרו איתנו קשר
            </Link>
            {
              ' עוד היום בטלפון או במייל ואנחנו נחזור אליכם בהקדם לתיאום פגישה או שיחת הכרות, ונשמח לפתח עבורכם אפליקציות WEB חדשניות ומובילות בהתאמה מדויקת לצרכים העסקיים שלכם.'
            }
          </>,
        ],
      },
    ],
  },
};
