import * as React from 'react';
import { graphql } from 'gatsby';
import Blog, {
  BlogSubMiniHeader,
  BlogSubHeader,
  BlogList,
  BlogListItem,
} from '../../components/Blog';
import { Paragraph } from '../../components/Common';
import { HE } from 'src/blogs/leading-trends-in-web-application-development';
import {
  LeadingTrendsInWebApplicationDevelopment,
  WinningSitesBuiltUsingReact,
  BenefitsOfWorkingWithSoftwareCompany,
  WhatDoseFullstackDeveloper,
} from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const articleSchemaData = {
  headline: "Leading Trends In Web Application Development",
  description: "SEO description Leading Trends In Web Application Development",
  image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fleading-trends-in-web-application-development.jpg?alt=media&token=6b722f16-12c1-4da5-9d63-441e9f08aa87",
  datePublished: '2022-03-05'
};

const LeadingTrendsInWebApplicationDevelopmentBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema("Leading Trends In Web Application Development", BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={LeadingTrendsInWebApplicationDevelopment.url}
      banner="/leading-trends-in-web-application-development/banner.jpg"
      relatedBlogs={[
        WhatDoseFullstackDeveloper,
        BenefitsOfWorkingWithSoftwareCompany,
        WinningSitesBuiltUsingReact,
      ]}
      title="Leading Trends In Web Application Development"
      seo={{
        title: 'SEO title Leading Trends In Web Application Development',
        description:
          'SEO description Leading Trends In Web Application Development',
        image:
          'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fleading-trends-in-web-application-development.jpg?alt=media&token=6b722f16-12c1-4da5-9d63-441e9f08aa87',
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map((p) => (
        <Paragraph>{p}</Paragraph>
      ))}

      <BlogSubHeader>{HE.section2.h}</BlogSubHeader>
      {HE.section2.p.map((p) => (
        <Paragraph>{p}</Paragraph>
      ))}

      <BlogSubHeader>{HE.section3.h}</BlogSubHeader>

      <BlogList>
        {HE.section3.ol.map((item) => (
          <BlogListItem>
            <BlogSubMiniHeader>{item.h3}</BlogSubMiniHeader>
            {item.p.map((p) => (
              <Paragraph>{p}</Paragraph>
            ))}
          </BlogListItem>
        ))}
      </BlogList>
    </Blog>
  );
}

export default LeadingTrendsInWebApplicationDevelopmentBlog;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
